import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent, NavigationExtras } from '@angular/router';
import { MenuController } from '@ionic/angular';
import firebase from 'firebase';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
  more = [];
  sections = [];
 

  


  // selectedPath = '';

  // pages = [
  //   {
  //     title: 'sermons',
  //     url: '/menu/tabs/sermons'
  //   },
  //   {
  //     title: 'events',
  //     url: '/menu/tabs/events'
  //   },
  //   {
  //     title: 'bible',
  //     url: '/menu/tabs/bible'
  //   },
  //   {
  //     title: 'news',
  //     url: '/menu/tabs/news'
  //   },
  //   {
  //     title: 'more',
  //     url: '/menu/tabs/more',
  //     children: [
  //       {
  //         title: 'About Us',
  //         url: '/tabs/more/about-us'
  //       },
  //       {
  //         title: 'Our Branches',
  //         url: '/tabs/more/branches'
  //       },
  //       {
  //         title: 'Our Leaders',
  //         url: '/tabs/more/leaders'
  //       },
  //       {
  //         title: 'Our Ministries',
  //         url: '/tabs/more/ministries'
  //       },
  //       {
  //         title: 'Connect With Us',
  //         url: '/tabs/more/connect'
  //       },
  //       {
  //         title: 'Giving',
  //         url: '/tabs/more/tithing'
  //       },
  //       {
  //         title: 'Inspirational Music',
  //         url: '/tabs/more/music'
  //       },
  //       {
  //         title: 'Get Social',
  //         url: '/tabs/more/social'
  //       },

  //     ]
  //   }
  // ];

 constructor(private router: Router, private menu: MenuController) {
  firebase.database().ref('sections').on('value', snapshot => {
    this.sections = (Object as any).values(snapshot.val());
    
  });

  
  
      
    


 
  // this.af.database.list('/users', { preserveSnapshot: true})
  //   .subscribe(snapshots=>{
  //       snapshots.forEach(snapshot => {
  //         console.log(snapshot.key, snapshot.val());
  //       });
  //   })

  firebase.database().ref('more').on('value', snapshot => {
    this.more = snapshot.val();
  });
}

  // this.router.events.subscribe((event: RouterEvent) => {
  //     if (event && event.url) {
  //       this.selectedPath = event.url;
  //     }
  //   });
  // }

  // menuSelect($event, item){
  
  //  switch(item.section){
  //       case 'bible': {
  //           this.router.navigateByUrl('/tabs/bible');
  //           console.log('Item',item.section);
  //           break;
  //       }
  //       case 'news': {
  //         this.router.navigateByUrl('/tabs/news');
  //         console.log('Item',item.section);
  //         break;
  //       }
  //       case 'events': {
  //         this.router.navigateByUrl('/tabs/events');
  //         console.log('Item',item.section);
  //         break;
  //       }
  //       case 'sermons': {
  //         this.router.navigateByUrl('/tabs/sermons');
  //         console.log('Item',item.section);
  //         break;
  //       }
  //       // default:
  //       //     this.router.navgiateByUrl('/tabs/folder',section)
  //     }
  //   }
  
  about($event, subitem) {
    const navigationExtras: NavigationExtras = {
      state: {subitem}
    };
    this.router.navigateByUrl('menu/tabs/more/about-us', navigationExtras);
  }

  branches($event, subitem) {
    const navigationExtras: NavigationExtras = {
      state: {subitem}
    };
    this.router.navigateByUrl('menu/tabs/more/branches', navigationExtras);
  }

  leaders($event, subitem) {
    const navigationExtras: NavigationExtras = {
      state: {subitem}
    };
    this.router.navigateByUrl('menu/tabs/more/leaders', navigationExtras);
  }

  ministries($event, subitem) {
    const navigationExtras: NavigationExtras = {
      state: {subitem}
    };
    this.router.navigateByUrl('menu/tabs/more/ministries', navigationExtras);
  }

  connect($event, subitem) {
   const navigationExtras: NavigationExtras = {
      state: {subitem}
    };
   this.router.navigateByUrl('menu/tabs/more/connect', navigationExtras);
  }

  tithing($event, subitem) {
    const navigationExtras: NavigationExtras = {
      state: {subitem}
    };
    this.router.navigateByUrl('menu/tabs/more/tithing', navigationExtras);
  }

  music($event, subitem) {
    const navigationExtras: NavigationExtras = {
      state: {subitem}
    };
    this.router.navigateByUrl('menu/tabs/more/music', navigationExtras);
  }

  social($event, subitem) {
    const navigationExtras: NavigationExtras = {
      state: {subitem}
    };
    this.router.navigateByUrl('menu/tabs/more/social', navigationExtras);
  }


  ngOnInit() {

  }

}