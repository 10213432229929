import { Component } from '@angular/core';

import { Platform, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import firebase from 'firebase';
import { OneSignal } from '@ionic-native/onesignal/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  
 constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private oneSignal: OneSignal,
    private alertCtrl: AlertController
  ) {

    // Firebase Configuration
    firebase.initializeApp({
      apiKey: 'AIzaSyB0VrVxFnB2iYW-SgLS0Zbgfaq7kOVDeYA',
      authDomain: 'mychurchdemo-c4153.firebaseapp.com',
      databaseURL: 'https://mychurchdemo-c4153.firebaseio.com',
      projectId: 'mychurchdemo-c4153',
      storageBucket: 'gs://mychurchdemo-c4153.appspot.com',
      messagingSenderId: '536994826005',
      appId: '1:536994826005:web:9114560543b7cb2d'
    });
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      // if (this.platform.is('cordova')) {
      //   this.setupPush();
      // }

    // OneSignal Configuration
      const notificationOpenedCallback = (jsonData) => {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      };

      window['plugins'].OneSignal
        .startInit('5363a83c-f9bb-4e9c-9b6b-4dd251b1e365', '536994826005')
        .handleNotificationOpened(notificationOpenedCallback)
        .endInit();
    });

  }
  // setupPush() {
  //   // I recommend to put these into your environment.ts
  //   this.oneSignal.startInit('a47d31b8-2bf0-41de-8f6a-0698bcfb96a1', '536994826005');

  //   this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
  //   // Notifcation was received in general
  //   this.oneSignal.handleNotificationReceived().subscribe(data => {
  //     let msg = data.payload.body;
  //     let title = data.payload.title;
  //     let additionalData = data.payload.additionalData;
  //     this.showAlert(title, msg, additionalData.task);
  //   });
 
  //   // Notification was really clicked/opened
  //   this.oneSignal.handleNotificationOpened().subscribe(data => {
  //     // Just a note that the data is a different place here!
  //     let additionalData = data.notification.payload.additionalData;
 
  //     this.showAlert('Notification opened', 'You already read this before', additionalData.task);
  //   });
 
  //   this.oneSignal.endInit();
  // }
 
  // async showAlert(title, msg, task) {
  //   const alert = await this.alertCtrl.create({
  //     header: title,
  //     subHeader: msg,
  //     buttons: [
  //       {
  //         text: `Action: ${task}`,
  //         handler: () => {
  //           // E.g: Navigate to a specific screen
  //         }
  //       }
  //     ]
  //   });
  //   alert.present();
  // }

}
