import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MenuPage } from './pages/menu/menu.page';

const routes: Routes = [
  {
  path: '',
  component: MenuPage,
  children: [
      {  path: '', redirectTo: 'menu/tabs/news', pathMatch: 'full' },
      { path: 'menu', loadChildren: './pages/menu/menu.module#MenuPageModule' },
      { path: '', loadChildren: './pages/tabs/tabs.module#TabsPageModule' },
      { path: 'event-details', loadChildren: './pages/event-details/event-details.module#EventDetailsPageModule' },
      { path: 'series', loadChildren: './pages/series/series.module#SeriesPageModule' },
      { path: 'episode', loadChildren: './pages/episode/episode.module#EpisodePageModule' },
      { path: 'news-article', loadChildren: './pages/news-article/news-article.module#NewsArticlePageModule' },
      { path: 'about-us', loadChildren: './pages/about-us/about-us.module#AboutUsPageModule' },
      { path: 'branches', loadChildren: './pages/branches/branches.module#BranchesPageModule' },
      { path: 'leaders', loadChildren: './pages/leaders/leaders.module#LeadersPageModule' },
      { path: 'ministries', loadChildren: './pages/ministries/ministries.module#MinistriesPageModule' },
      { path: 'connect', loadChildren: './pages/connect/connect.module#ConnectPageModule' },
      { path: 'tithing', loadChildren: './pages/tithing/tithing.module#TithingPageModule' },
      { path: 'music', loadChildren: './pages/music/music.module#MusicPageModule' },
      { path: 'social', loadChildren: './pages/social/social.module#SocialPageModule' },
      { path: 'ministry-details', loadChildren: './pages/ministry-details/ministry-details.module#MinistryDetailsPageModule' },
      { path: 'location-map', loadChildren: './pages/location-map/location-map.module#LocationMapPageModule' },
      { path: 'sermons', loadChildren: './pages/sermons/sermons.module#SermonsPageModule' },
      { path: 'events', loadChildren: './pages/events/events.module#EventsPageModule' },
      { path: 'bible', loadChildren: './pages/bible/bible.module#BiblePageModule' },
      { path: 'news', loadChildren: './pages/news/news.module#NewsPageModule' },
      { path: 'more', loadChildren: './pages/more/more.module#MorePageModule' },
      { path: 'image', loadChildren: './pages/image/image.module#ImagePageModule' },
      { path: 'video', loadChildren: './pages/video/video.module#VideoPageModule' },
      { path: 'audio', loadChildren: './pages/audio/audio.module#AudioPageModule' },
      { path: 'pdf', loadChildren: './pages/pdf/pdf.module#PdfPageModule' },
      { path: 'event-calendar', loadChildren: './pages/event-calendar/event-calendar.module#EventCalendarPageModule' }
    ]
  },
  

  

]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
