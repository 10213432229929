import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Calendar } from '@ionic-native/calendar/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { MenuPageModule } from './pages/menu/menu.module';

import { File } from '@ionic-native/File/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { HttpClientModule } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, MenuPageModule,
    HttpClientModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Calendar,
    SocialSharing,
    OneSignal,
    MenuPageModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    File,
    FileOpener,
    FileTransfer,
    DocumentViewer,
    HTTP,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
