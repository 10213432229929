import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import {TabsPage} from './tabs.page';

export function imageMatch(url: UrlSegment[]) {
      return  url.length > 0 && url[url.length -1].path == 'image' ? ({consumed: url}) : null;
     }

export function folderMatch(url: UrlSegment[]) {
        return  url.length > 0 && url[url.length -1].path == 'folder' ? ({consumed: url}) : null;
       }
export function sectionMatch(url: UrlSegment[]) {
        return  url.length > 0 && url[url.length -1].path == 'section' ? ({consumed: url}) : null;
       }

export function videoMatch(url: UrlSegment[]) {
        return  url.length > 0 && url[url.length -1].path == 'video' ? ({consumed: url}) : null;
       }

export function pdfMatch(url: UrlSegment[]) {
        return  url.length > 0 && url[url.length -1].path == 'pdf' ? ({consumed: url}) : null;
       }
export function audioMatch(url: UrlSegment[]) {
        return  url.length > 0 && url[url.length -1].path == 'audio' ? ({consumed: url}) : null;
       }
       


export const routes: Routes = [
       {
            path: 'menu/tabs',
            component: TabsPage,
            children: [
                { path: 'sermons', loadChildren: '../sermons/sermons.module#SermonsPageModule' },
                { path: 'sermons/series', loadChildren: '../series/series.module#SeriesPageModule' },
                { path: 'sermons/series/episode', loadChildren: '../episode/episode.module#EpisodePageModule' },
                { path: 'events', loadChildren: '../events/events.module#EventsPageModule'},
                { path: 'events/event-details', loadChildren: '../event-details/event-details.module#EventDetailsPageModule'},
                { path: 'bible', loadChildren: '../bible/bible.module#BiblePageModule' },
                { path: 'news', loadChildren: '../news/news.module#NewsPageModule' },
                { path: 'news/news-article', loadChildren: '../news-article/news-article.module#NewsArticlePageModule' },
                { path: 'more', loadChildren: '../more/more.module#MorePageModule' },
                { path: 'more/about-us', loadChildren: '../about-us/about-us.module#AboutUsPageModule' },
                { path: 'more/branches', loadChildren: '../branches/branches.module#BranchesPageModule' },
                { path: 'more/leaders', loadChildren: '../leaders/leaders.module#LeadersPageModule' },
                { path: 'more/ministries', loadChildren: '../ministries/ministries.module#MinistriesPageModule' },
                { path: 'more/ministries/ministry-details',
                    loadChildren: '../ministry-details/ministry-details.module#MinistryDetailsPageModule' },
                { path: 'more/connect', loadChildren: '../connect/connect.module#ConnectPageModule' },
                { path: 'more/tithing', loadChildren: '../tithing/tithing.module#TithingPageModule' },
                { path: 'more/music', loadChildren: '../music/music.module#MusicPageModule' },
                { path: 'more/social', loadChildren: '../social/social.module#SocialPageModule' },
                //{ path: 'my-section', loadChildren: '../my-section/my-section.module#MySectionPageModule' },
                // { matcher: sectionMatch, loadChildren: '../my-section/my-section.module#MySectionPageModule' },
                { matcher: imageMatch, loadChildren: '../image/image.module#ImagePageModule' },
                { matcher: videoMatch, loadChildren: '../video/video.module#VideoPageModule' },
                { matcher: folderMatch, loadChildren: '../my-section/my-section.module#MySectionPageModule' },
                { matcher: pdfMatch, loadChildren: '../pdf/pdf.module#PdfPageModule' },
                { matcher: audioMatch, loadChildren: '../audio/audio.module#AudioPageModule' },
                { path: 'event-calendar', loadChildren: '../event-calendar/event-calendar.module#EventCalendarPageModule' }
              ]
          },
          { 
            path: '',
            redirectTo: 'menu/tabs/news',
            pathMatch:  'full'
          }
        ];


@NgModule({
    imports:
        [
            RouterModule.forChild(routes)
        ],
    exports:
        [
            RouterModule
        ]
})
export class TabsPageRoutingModule { }